import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import Button from "@material-ui/core/Button";
import { Input, Spinner } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import { registo } from "../../actions/userActions";

export default class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      nome: "",
      loading: false
    };
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangeNome = this.handleChangeNome.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleChangeEmail(event) {
    this.setState({
      email: event.target.value
    });
  }

  handleChangePassword(event) {
    this.setState({
      password: event.target.value
    });
  }

  handleChangeNome(event) {
    this.setState({
      nome: event.target.value
    });
  }

  registerAccount = async e => {
    this.setState({ loading: true });
    registo(this.state.email, this.state.password, this.state.nome).then(
      data => {
        if (data.message) {
          this.props.updateMoradas();
          this.props.changeCheckoutDisplay();
          this.props.changeRegistoDisplay();
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false });
          alert(data.message);
        }
      }
    );
  };

  handleKeyPress(target) {
    if (target.charCode == 13) {
      this.setState({ loading: true });
      registo(this.state.email, this.state.password, this.state.nome).then(
        data => {
          if (data.message) {
            this.props.updateMoradas();
            this.props.changeCheckoutDisplay();
            this.props.changeRegistoDisplay();
            this.setState({ loading: false });
          } else {
            this.setState({ loading: false });
            alert(data.message);
          }
        }
      );
    }
  }

  render() {
    return (
      <Row
        style={{
          maxWidth: "100vw",
          display: this.props.display ? "block" : "none"
        }}
        id="registo"
      >
        <Col lg={12}>
          <Row style={{ textAlign: "center" }}>
            <Col lg={{ span: 10, offset: 1 }}>
              <br />
              <br />
              <h2 style={{ fontSize: isMobile ? "26px" : "2rem" }}>
                <b>{this.props.title}</b>
              </h2>
              <br />
            </Col>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col lg={{ span: 4, offset: 4 }}>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Endereço email"
                value={this.state.email}
                onChange={this.handleChangeEmail}
                onKeyPress={this.handleKeyPress}
                style={{ maxWidth: isMobile ? "100%" : "100%" }}
                fullWidth={true}
              />
            </Col>
          </Row>
          <br />
          <Row style={{ textAlign: "center" }}>
            <Col lg={{ span: 4, offset: 4 }}>
              <Input
                type="text"
                name="nome"
                id="nome"
                placeholder="Nome Completo"
                value={this.state.nome}
                onChange={this.handleChangeNome}
                onKeyPress={this.handleKeyPress}
                style={{ maxWidth: isMobile ? "100%" : "100%" }}
                fullWidth={true}
              />
            </Col>
          </Row>
          <br />
          <Row style={{ textAlign: "center" }}>
            <Col lg={{ span: 4, offset: 4 }}>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="Palavra-Passe"
                value={this.state.password}
                onChange={this.handleChangePassword}
                onKeyPress={this.handleKeyPress}
                style={{ maxWidth: isMobile ? "100%" : "100%" }}
                fullWidth={true}
              />
            </Col>
          </Row>
          <br />
          <Row style={{ textAlign: "right" }}>
            <Col
              lg={{ span: 4, offset: 4 }}
              className="fake-link"
              onClick={() => {
                this.props.changeRegistoDisplay();
                this.props.changeLoginDisplay();
              }}
            >
              Voltar ao Login
            </Col>
          </Row>
          <br />
          <Row style={{ textAlign: "center" }}>
            <Col lg={12}>
              {this.state.loading ? (
                <Button
                  variant={"outlined"}
                  disabled
                  style={{
                    background: "#ca006a",
                    color: "white",
                    width: isMobile ? "70%" : "20%"
                  }}
                >
                  <Spinner size="sm" color="white" />
                </Button>
              ) : (
                <Button
                  variant={"outlined"}
                  style={{
                    background: "#ca006a",
                    color: "white",
                    width: isMobile ? "70%" : "20%"
                  }}
                  onClick={() => {
                    this.registerAccount();
                  }}
                >
                  Registar
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
