import React, { Component, Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import Button from "@material-ui/core/Button";
import { Row, Col, Container } from "react-bootstrap";
import KeyboardVoiceIcon from "@material-ui/icons/KeyboardVoice";
import Icon from "@material-ui/core/Icon";

import {
  addAddress,
  getMoradasFaturacao,
  getMorada
} from "../../actions/userActions";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import CircularProgress from "@material-ui/core/CircularProgress";

import { isMobile } from "react-device-detect";

export default class Billing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newAddressShow: true
    };
  }

  render() {
    return (
      <div>
        {/* Obter moradas */}
        <Formik
          enableReinitialize
          /* Dados a enviar para a API */
          initialValues={{
            morada: this.props.moradaSelecionada.ID_Cliente_Morada
          }}
          onChange={(values, { setSubmitting }) => {
            alert(values);
          }}
          onSubmit={(values, { setSubmitting }) => {
            this.props.getAddBillingAddressCall();
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
            /* and other goodies */
          }) => (
            <form
              onSubmit={handleSubmit}
              style={{ display: this.props.show ? "block" : "none" }}
            >
              <br />
              <br />
              <Row>
                <Col lg={12} style={{ textAlign: "left" }}>
                  <a
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={this.props.showAddToCart}
                  >
                    {"<"} Voltar
                  </a>
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: "3vh",
                  marginBottom: "3vh"
                }}
              >
                <Col lg={12}>Escolha os dados de faturação</Col>
              </Row>
              {this.props.moradas.length > 0 ? (
                <Row>
                  <Col lg={12}>
                    <FormControl fullWidth>
                      <Select
                        value={values.morada}
                        onChange={this.props.handleChangeAddress}
                        inputProps={{
                          name: "morada"
                        }}
                      >
                        {this.props.moradas.map(function(x) {
                          return (
                            <MenuItem value={x.ID_Cliente_Morada}>
                              {x.Morada}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                </Row>
              ) : null}
              {this.props.moradas.length > 0 ? (
                <Row>
                  <Col lg={12}>
                    <TextField
                      disabled
                      id="outlined-required"
                      defaultValue=""
                      margin="normal"
                      onBlur={handleBlur}
                      value={"Nome: " + this.props.moradaSelecionada.Nome}
                      fullWidth
                      type="text"
                      name="nomeCompleto"
                    />
                  </Col>
                </Row>
              ) : null}
              {this.props.moradas.length > 0 ? (
                <Row>
                  <Col lg={12}>
                    <TextField
                      disabled
                      id="outlined-required"
                      defaultValue=""
                      margin="normal"
                      onBlur={handleBlur}
                      value={
                        "Morada: " +
                        this.props.moradaSelecionada.Morada +
                        ", " +
                        this.props.moradaSelecionada.CodigoPostal +
                        ", " +
                        this.props.moradaSelecionada.Localidade +
                        ", " +
                        this.props.moradaSelecionada.Pais
                      }
                      fullWidth
                      type="text"
                      name="morada"
                    />
                  </Col>
                </Row>
              ) : null}
              {this.props.moradas.length > 0 ? (
                <Row>
                  <Col lg={12}>
                    <TextField
                      disabled
                      id="outlined-required"
                      defaultValue=""
                      margin="normal"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={"NIF: " + this.props.moradaSelecionada.Nif}
                      fullWidth
                      type="text"
                      name="nif"
                      error={errors.email}
                    />
                  </Col>
                </Row>
              ) : null}
              <br />
              <Row>
                <Col lg={this.props.moradas.length > 0 ? 6 : 12}>
                  {" "}
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={this.props.showNewAddress}
                  >
                    Nova morada
                    {/*<KeyboardVoiceIcon />*/}
                  </Button>
                </Col>
                {this.props.moradas.length > 0 ? (
                  <Col lg={6}>
                    {" "}
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={isSubmitting}
                      fullWidth
                      style={{ marginTop: isMobile ? "2vh" : "none" }}
                    >
                      {isSubmitting ? <CircularProgress /> : "Prosseguir"}
                      {/*<KeyboardVoiceIcon />*/}
                    </Button>
                  </Col>
                ) : null}
              </Row>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}
