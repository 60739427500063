import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";

export default class HeroText extends React.Component {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          minWidth: "100%",
          height: "100vh",
          top: isMobile ? "32vh" : "40vh",
          zIndex: "2"
        }}
      >
        <h1 style={{ fontSize: isMobile ? "3vh" : "3rem" }}>
          {this.props.title}
        </h1>
        <h2
          style={{
            fontSize: isMobile ? "2vh" : "1.7rem",
            color: "#034275",
            marginTop: "2vh"
          }}
        >
          {this.props.description1}
        </h2>
        <h5
          style={{
            fontSize: isMobile ? "2vh" : "1.7rem",
            color: "#034275",
            marginTop: "2vh"
          }}
        >
          {this.props.description2}
        </h5>
      </div>
    );
  }
}
