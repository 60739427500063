import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";

import ServiceImage from "./ServiceImage";

export default class Service extends React.Component {
  render() {
    return this.props.side === "left" ? (
      <Row style={{ alignItems: "center" }}>
        <Col xs={3} sm={3} md={3} lg={3} style={{ textAlign: "right" }}>
          <ServiceImage imageSrc={this.props.imageSrc} />
        </Col>
        <Col
          xs={9}
          sm={9}
          md={9}
          lg={9}
          style={{ textAlign: "right", alignItems: "center" }}
        >
          <Row>
            <Col lg={12} style={{ fontSize: "22px", color: "#ca006a" }}>
              <b>{this.props.description1}</b>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>{this.props.description2}</Col>
          </Row>
        </Col>
      </Row>
    ) : (
      <Row style={{ alignItems: "center" }}>
        <Col
          xs={9}
          sm={9}
          md={9}
          lg={9}
          style={{ textAlign: "left", alignItems: "center" }}
        >
          <Row>
            <Col lg={12} style={{ fontSize: "22px", color: "#ca006a" }}>
              <b>{this.props.description1}</b>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>{this.props.description2}</Col>
          </Row>
        </Col>
        <Col xs={3} sm={3} md={3} lg={3} style={{ textAlign: "left" }}>
          <ServiceImage imageSrc={this.props.imageSrc} />
        </Col>
      </Row>
    );
  }
}
