import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button";

export default class BoxButton extends React.Component {
  render() {
    return (
      <Row>
        <Col lg={12}>
          <Button
            variant={this.props.variant}
            style={{
              color: "white",
              padding: "10px",
              backgroundColor: "rgb(202, 0, 106)",
              fontSize: "1.6vh"
            }}
          >
            {this.props.buttonText}
          </Button>
        </Col>
      </Row>
    );
  }
}
