import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { Link } from "react-scroll";

export default class JourneyButton extends React.Component {
  render() {
    return (
      <Row>
        <Col lg={12}>
          <Link
            activeClass="active"
            to="Pricing"
            spy={true}
            smooth={true}
            duration={1300}
          >
            <Button
              variant={this.props.variant}
              style={{
                color: "white",
                padding: "15px",
                backgroundColor: "rgb(202, 0, 106)",
                fontSize: "1.6vh"
              }}
            >
              {this.props.buttonText}
            </Button>
          </Link>
        </Col>
      </Row>
    );
  }
}
