import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import Advantage from "./Advantage";

export default class Advantages extends React.Component {
  render() {
    return (
      <Row style={{ maxWidth: "100vw" }}>
        <Col lg={12}>
          <Row style={{ textAlign: "center" }}>
            <Col lg={{ span: 8, offset: 2 }}>
              <h2 style={{ fontSize: isMobile ? "26px" : "2rem" }}>
                <b>{this.props.title}</b>
              </h2>
              <br />
              <h3 style={{ fontSize: isMobile ? "19px" : "1.75rem" }}>
                {this.props.description}
              </h3>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col lg={{ span: 10, offset: 1 }}>
              <Row>
                <Advantage
                  title={"Tornar o mundo melhor"}
                  description={
                    "Reduzindo o material físico com conteúdo 100% online"
                  }
                />
                <Advantage
                  title={"Aproveitar o tempo"}
                  description={
                    "Utilizar o tempo, em casa, em viagem, no trabalho, no desporto, para aprender e evoluir para ser mais feliz"
                  }
                />
                <Advantage
                  title={"Melhorar a vida"}
                  description={
                    "Construir novas crenças, melhorar atitudes gerando resultados extraordinários a partir de hoje"
                  }
                />
                <Advantage
                  title={"Deixar a minha marca"}
                  description={
                    "Ser o primeiro, fazendo escolhas certas e partilhando este conheciemnto com os outross"
                  }
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
