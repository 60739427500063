import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import PricingElement from "./PricingElement";

export default class Pricing extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Row style={{ maxWidth: "100vw" }} id="Pricing">
        <Col lg={12}>
          <Row style={{ textAlign: "center" }}>
            <Col lg={{ span: 10, offset: 1 }}>
              <br />
              <br />
              <h2 style={{ fontSize: isMobile ? "26px" : "2rem" }}>
                <b>{this.props.title}</b>
              </h2>
              <br />
              <h3 style={{ fontSize: isMobile ? "19px" : "1.75rem" }}>
                {this.props.description}
              </h3>
            </Col>
          </Row>
          <div
            id="generic_price_table"
            style={{
              margin: isMobile ? null : "100px",
              marginBottom: isMobile ? null : "35px"
            }}
          >
            <section>
              <Row>
                <PricingElement
                  title={"Power"}
                  currency={"34"}
                  cent={".99 €"}
                  earlierPrice={"200 €"}
                  subscriptionType={"Mês"}
                  conditions={[
                    {
                      text: "Adaptado para todos os dispositivos",
                      active: true
                    },
                    {
                      text: "Conteúdo online para todas as idades",
                      active: true
                    },
                    { text: "Suporte especializado", active: true },
                    { text: "Acessivel 24h por dia", active: true },
                    {
                      text: "Ganhe 5% ao recomendar aos seus amigos*",
                      active: true
                    },
                    {
                      text:
                        "Acesso a 10 Audiobooks, à sua escolha, durante 30 dias",
                      active: true
                    },
                    {
                      text: "Acesso a TODOS os Audiobooks durante todo o ano",
                      active: false
                    },
                    {
                      text:
                        "Actualizações mensais do programa Vitaminas para a Mente",
                      active: false
                    },
                    {
                      text: "Acesso a webinares com temáticas especiais",
                      active: false
                    },
                    {
                      text: "Acesso prioritário a cursos e eventos",
                      active: false
                    }
                  ]}
                  button_ID={"Power"}
                  buttonText={"Subscreva já"}
                  active={false}
                  ID_Pack={this.props.ID_Pack_1}
                  updatePack={this.props.updatePack}
                  showCheckout={this.props.showCheckout}
                />
                <PricingElement
                  title={"Power Plus"}
                  currency={"364"}
                  cent={".99 €"}
                  earlierPrice={"1440 €"}
                  subscriptionType={"Ano"}
                  conditions={[
                    {
                      text: "Adaptado para todos os dispositivos",
                      active: true
                    },
                    {
                      text: "Conteúdo online para todas as idades",
                      active: true
                    },
                    { text: "Suporte especializado", active: true },
                    { text: "Acessivel 24h por dia", active: true },
                    {
                      text: "Ganhe 10% ao recomendar aos seus amigos*",
                      active: true
                    },
                    {
                      text:
                        "Acesso a 10 Audiobooks, à sua escolha, durante 30 dias",
                      active: false
                    },
                    {
                      text: "Acesso a TODOS os Audiobooks durante todo o ano",
                      active: true
                    },
                    {
                      text:
                        "Actualizações mensais do programa Vitaminas para a Mente",
                      active: false
                    },
                    {
                      text: "Acesso a webinares com temáticas especiais",
                      active: false
                    },
                    {
                      text: "Acesso prioritário a cursos e eventos",
                      active: false
                    }
                  ]}
                  button_ID={"PowerPlus"}
                  buttonText={"Subscreva já"}
                  active={false}
                  ID_Pack={this.props.ID_Pack_2}
                  updatePack={this.props.updatePack}
                  showCheckout={this.props.showCheckout}
                />
                <PricingElement
                  title={"Excellence"}
                  currency={"499"}
                  cent={".99 €"}
                  earlierPrice={"1695 €"}
                  subscriptionType={"Ano"}
                  conditions={[
                    {
                      text: "Adaptado para todos os dispositivos",
                      active: true
                    },
                    {
                      text: "Conteúdo online para todas as idades",
                      active: true
                    },
                    { text: "Suporte especializado", active: true },
                    { text: "Acessivel 24h por dia", active: true },
                    {
                      text: "Ganhe 10% ao recomendar aos seus amigos*",
                      active: true
                    },
                    {
                      text:
                        "Acesso a 10 Audiobooks, à sua escolha, durante 30 dias",
                      active: false
                    },
                    {
                      text: "Acesso a TODOS os Audiobooks durante todo o ano",
                      active: true
                    },
                    {
                      text:
                        "Actualizações mensais do programa Vitaminas para a Mente",
                      active: true
                    },
                    {
                      text: "Acesso a webinares com temáticas especiais",
                      active: true
                    },
                    {
                      text: "Acesso prioritário a cursos e eventos",
                      active: true
                    }
                  ]}
                  button_ID={"Excellence"}
                  buttonText={"Subscreva já"}
                  active={false}
                  ID_Pack={this.props.ID_Pack_3}
                  updatePack={this.props.updatePack}
                  showCheckout={this.props.showCheckout}
                />
              </Row>
            </section>
          </div>
          <Row>
            <Col
              style={{
                fontSize: this.props.altTextFontSize,
                color: this.props.altTextColor,
                textAlign: this.props.altTextAlign
              }}
            >
              {this.props.altText}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
