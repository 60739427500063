// Login
export function login(email, password) {
  return new Promise((resolve, reject) => {
    return fetch("/user/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        email: email,
        password: password
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        reject(error);
      });
  });
}

// Registo
export function registo(email, password, nome) {
  return new Promise((resolve, reject) => {
    return fetch("/user/registo", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        email: email,
        password: password,
        nome: nome
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => console.log(error));
  });
}

// obter moradas
export function getMoradasFaturacao() {
  return new Promise((resolve, reject) => {
    return fetch("/user/getMoradas", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => console.log(error));
  });
}

// obter moradas
export function getMorada(ID_Cliente_Morada) {
  return new Promise((resolve, reject) => {
    return fetch("/user/getMorada", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Cliente_Morada: ID_Cliente_Morada
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => console.log(error));
  });
}

// Obter tipos de pagamento
export function getTiposPagamento() {
  return new Promise((resolve, reject) => {
    return fetch("/user/getPaymentMethods", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => console.log(error));
  });
}

// Obter paises
export function getPaises() {
  return new Promise((resolve, reject) => {
    return fetch("/user/getPaises", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => console.log(error));
  });
}
 
// adicionar morada
export function addAddress(address) {
  return new Promise((resolve, reject) => {   
    return fetch("/user/addMorada", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        morada: address.morada,
        localidade: address.localidade,
        codigo_postal: address.codigo_postal,
        id_pais: address.id_pais,
        nome: address.nome,
        nif: address.nif
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => console.log(error));
  });
}

// confirmar encomenda
export function checkout(checkout) {
  return new Promise((resolve, reject) => {
    return fetch("/user/checkout", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_TipoPagamento: checkout.ID_TipoPagamento,
        ID_ClienteMoradaFatura: checkout.ID_Cliente_Morada
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => console.log(error));
  });
}

export function adicionarPack(ID_Pack) {
  return new Promise((resolve, reject) => {
    return fetch("/user/adicionarPack", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Pack: ID_Pack
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

export function getOrderPayment(hash) {
  return new Promise((resolve, reject) => {
    return fetch("/user/getOrderPayment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        hash: hash
      })
    })
      .then(res => res.json())
      .then(json => {
        console.log(json);
        resolve({ data: json });
      })
      .catch(error => console.log(error));
  });
}

export function paymentPaypalConfirmed(id_encomenda) {
  return new Promise((resolve, reject) => {
    return fetch("/user/paypalConfirmed", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        orderID: id_encomenda
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve({ data: json });
      })
      .catch(error => console.log(error));
  });
}

export function getPackPrice(ID_Pack) {
  return new Promise((resolve, reject) => {
    return fetch("/user/getPackPrice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Pack: ID_Pack
      })
    })
      .then(res => res.json())
      .then(json => {
        console.log(json);
        resolve({ data: json });
      })
      .catch(error => console.log(error));
  });
}
