import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Link } from "react-scroll";

export default class HeroKnowMore extends React.Component {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          minWidth: "100%",
          bottom: isMobile ? "5vh" : "5vh",
          zIndex: "2"
        }}
      >
        <Link
          className="knowMoreLink"
          activeClass="active"
          to="BoxServices"
          spy={true}
          smooth={true}
          duration={1300}
        >
          <span
            style={{
              fontSize: isMobile ? "2vh" : "20px",
              color: this.props.color
            }}
          >
            <b>{this.props.text}</b>
          </span>
          <br />
          <svg
            width={isMobile ? "5vh" : "50px"}
            height="50px"
            viewBox="0 0 129 129"
          >
            <g>
              <path
                style={{ fill: this.props.color }}
                d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z"
              />
            </g>
          </svg>
        </Link>
      </div>
    );
  }
}
