import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { Input, Spinner } from "reactstrap";
import { isMobile } from "react-device-detect";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { login } from "../../actions/userActions";
import $ from "jquery";
import { Element } from "react-scroll";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false
    };
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleChangeEmail(event) {
    this.setState({
      email: event.target.value
    });
  }

  handleChangePassword(event) {
    this.setState({
      password: event.target.value
    });
  }

  loginAccount = async e => {
    this.setState({ loading: true });
    login(this.state.email, this.state.password).then(data => {
      if (data.message) {
        this.props.changeLoginDisplay();
        this.props.changeCheckoutDisplay();
        this.setState({ loading: false });
      } else {
        alert(data.message);
        this.setState({ loading: false });
      }
    });
  };

  handleKeyPress(target) {
    if (target.charCode == 13) {
      this.setState({ loading: true });
      login(this.state.email, this.state.password).then(data => {
        if (data.message) {
          this.props.changeLoginDisplay();
          this.props.changeCheckoutDisplay();
          this.setState({ loading: false });
        } else {
          alert(data.message);
          this.setState({ loading: false });
        }
      });
    }
  }

  render() {
    return (
      <Element name="login" className="element">
        <Row
          id="login1"
          style={{
            maxWidth: "100vw",
            display: this.props.display ? "node" : "none"
          }}
        >
          <Col lg={12}>
            <Row style={{ textAlign: "center" }}>
              <Col lg={{ span: 10, offset: 1 }}>
                <br />
                <br />
                <h2 style={{ fontSize: isMobile ? "26px" : "2rem" }}>
                  <b>{this.props.title}</b>
                </h2>
                <br />
              </Col>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col lg={{ span: 4, offset: 4 }}>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Endereço email"
                  value={this.state.email}
                  onChange={this.handleChangeEmail}
                  onKeyPress={this.handleKeyPress}
                  style={{ maxWidth: isMobile ? "100%" : "100%" }}
                  fullWidth={true}
                />
              </Col>
            </Row>
            <br />
            <Row style={{ textAlign: "center" }}>
              <Col lg={{ span: 4, offset: 4 }}>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Palavra-Passe"
                  value={this.state.password}
                  onChange={this.handleChangePassword}
                  onKeyPress={this.handleKeyPress}
                  style={{ maxWidth: isMobile ? "100%" : "100%" }}
                  fullWidth={true}
                />
              </Col>
            </Row>
            <br />
            <Row style={{ textAlign: "right" }}>
              <Col lg={{ span: 4, offset: 4 }} className="fake-link">
                <a href="https://www.ihavethepower.net/MyIHTP/Create">
                  {" "}
                  Ainda não tem conta? Registe-se!
                </a>
              </Col>
            </Row>
            <br />
            <Row style={{ textAlign: "center" }}>
              <Col lg={12}>
                {this.state.loading ? (
                  <Button
                    variant={"outlined"}
                    disabled
                    style={{
                      background: "#ca006a",
                      color: "white",
                      width: isMobile ? "70%" : "20%"
                    }}
                  >
                    <Spinner size="sm" color="white" />
                  </Button>
                ) : (
                  <Button
                    variant={"outlined"}
                    style={{
                      background: "#ca006a",
                      color: "white",
                      width: isMobile ? "70%" : "20%"
                    }}
                    onClick={() => {
                      this.props.updateMoradas();
                      this.loginAccount();
                    }}
                  >
                    Login
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Element>
    );
  }
}
