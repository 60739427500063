import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import { Link } from "react-scroll";

export default class ScrollTop extends React.Component {
  render() {
    return (
      <Link
        activeClass="active"
        className="scrollToTop"
        style={{
          position: "fixed",
          bottom: isMobile ? "3vh" : "3vh",
          right: isMobile ? "2vw" : "1vw",
          borderRadius: "50%",
          width: "55px",
          height: "55px",
          display: "none",
          boxShadow: "rgba(0, 0, 0, 0.75) 0px 0px 17px 1px"
        }}
        to="top"
        spy={true}
        smooth={true}
        duration={1500}
      >
        <svg
          className="scrollToTopSVG"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="55"
          height="55px"
          viewBox="0 0 438.533 438.533"
        >
          <g>
            <path
              d="M409.133,109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.736,9.801,259.058,0,219.273,0
  c-39.781,0-76.47,9.801-110.063,29.407c-33.595,19.604-60.192,46.201-79.8,79.796C9.801,142.8,0,179.489,0,219.267
  c0,39.78,9.804,76.463,29.407,110.062c19.607,33.592,46.204,60.189,79.799,79.798c33.597,19.605,70.283,29.407,110.063,29.407
  s76.47-9.802,110.065-29.407c33.593-19.602,60.189-46.206,79.795-79.798c19.603-33.596,29.403-70.284,29.403-110.062
  C438.533,179.485,428.732,142.795,409.133,109.203z M361.74,259.517l-29.123,29.129c-3.621,3.614-7.901,5.424-12.847,5.424
  c-4.948,0-9.236-1.81-12.847-5.424l-87.654-87.653l-87.646,87.653c-3.616,3.614-7.898,5.424-12.847,5.424
  c-4.95,0-9.233-1.81-12.85-5.424l-29.12-29.129c-3.617-3.607-5.426-7.898-5.426-12.847c0-4.942,1.809-9.227,5.426-12.848
  l129.62-129.616c3.617-3.617,7.898-5.424,12.847-5.424s9.238,1.807,12.846,5.424L361.74,233.822
  c3.613,3.621,5.424,7.905,5.424,12.848C367.164,251.618,365.357,255.909,361.74,259.517z"
            />
          </g>
        </svg>
      </Link>
    );
  }
}
