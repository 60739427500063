import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";

export default class DrawnForYouText extends React.Component {
  render() {
    return (
      <Fragment>
        &bull; {this.props.text1} <br />
        <br style={{ display: isMobile ? "none" : "block" }} />
        <br />
        &bull; {this.props.text2} <br />
        <br style={{ display: isMobile ? "none" : "block" }} />
        <br />
        &bull; {this.props.text3}
      </Fragment>
    );
  }
}
