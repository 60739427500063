import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";

export default class Advantage extends React.Component {
  render() {
    return (
      <Col lg={3}>
        <Row>
          <Col
            lg={12}
            style={{
              fontSize: "22px",
              color: "#ca006a"
            }}
          >
            <b style={{ color: "rgb(3, 66, 117)" }}>{this.props.title}</b>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: isMobile ? "2vh" : "1.75rem",
            marginBottom: isMobile ? "5vh" : "1.75rem",
            lineHeight: isMobile ? "7vh" : "2.5rem"
          }}
        >
          <Col lg={12}>{this.props.description}</Col>
        </Row>
      </Col>
    );
  }
}
