import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { ParallaxBanner } from "react-scroll-parallax";

export default class HeaderImage extends React.Component {
  render() {
    return (
      <ParallaxBanner
        className={this.props.className}
        layers={[
          {
            image: this.props.imageSrc,
            amount: this.props.amount
          }
        ]}
        style={{
          height: this.props.height
        }}
      ></ParallaxBanner>
    );
  }
}
