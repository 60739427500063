import React, { Component, Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import Button from "@material-ui/core/Button";
import { Row, Col, Container } from "react-bootstrap";
import { login } from "../../actions/userActions";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import CircularProgress from "@material-ui/core/CircularProgress";

export default class AddProduct extends React.Component {
  constructor(props) {
    super(props);
  }

  loginAccount = async e => {
    this.setState({ loading: true });
  };

  componentDidMount() {
    var that = this;
  }

  render() {
    {
      /*validate={values => {
      let errors = {};
      if (!values.email) {
        errors.email = "Email necessário";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Endereço de email inválido";
      }
      return errors;
    }}*/
    }
    return (
      <Formik
        /* Dados a enviar para a API */
        enableReinitialize
        initialValues={{ id_pack: this.props.id_pack_selecionado }}
        onSubmit={(values, { setSubmitting }) => {
          this.props.getSubscribedPack(values.id_pack);
          this.props.getAddToCartCall(values.id_pack);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
          /* and other goodies */
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{ display: this.props.show ? "block" : "none" }}
          >
            <br />
            <br />
            <Row style={{}}>
              <Col lg={12} style={{ textAlign: "left" }}>
                <a
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={this.props.showLogin}
                >
                  {"<"} Voltar
                </a>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: "3vh",
                marginBottom: "3vh"
              }}
            >
              <Col lg={12}>
                <h5 style={{ textAlign: "justify", lineHeight: "4vh" }}>
                  Excelente escolha. O valor a pagar foi ajustado
                  automaticamente, em função do seu perfil de cliente
                </h5>
              </Col>
            </Row>
            <FormControl fullWidth>
              <Select
                value={values.id_pack}
                onChange={handleChange}
                inputProps={{
                  name: "id_pack",
                  id: "id_pack"
                }}
                style={{ width: "100%" }}
              >
                <MenuItem value="" disabled>
                  Escolha uma subscrição
                </MenuItem>
                <MenuItem
                  value={process.env.NODE_ENV === "development" ? 851 : 848}
                >
                  Power - {this.props.precoPackPower}
                </MenuItem>
                <MenuItem
                  value={process.env.NODE_ENV === "development" ? 852 : 849}
                >
                  Power Plus - {this.props.precoPackPowerPlus}
                </MenuItem>
                <MenuItem value={850}>
                  Excellence - {this.props.precoPackExcellence}
                </MenuItem>
              </Select>
            </FormControl>

            {/*<FormHelperText>Escolha uma subscrição</FormHelperText>*/}
            <br />
            <br />
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isSubmitting}
              style={{ width: "100%" }}
            >
              {isSubmitting ? <CircularProgress /> : "Subscrever"}
            </Button>
          </form>
        )}
      </Formik>
    );
  }
}
