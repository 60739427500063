import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { ParallaxBanner } from "react-scroll-parallax";

export default class Parallax extends React.Component {
  render() {
    return (
      <Row
        style={{
          maxWidth: "100vw"
        }}
      >
        <Col lg={12} style={{ padding: "0px" }}>
          <ParallaxBanner
            className={this.props.className}
            layers={[
              {
                image: this.props.imageSrc,
                amount: this.props.amount
              }
            ]}
            style={{
              height: this.props.height
            }}
          />
        </Col>
      </Row>
    );
  }
}
