import React, { Component, Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import Button from "@material-ui/core/Button";
import { Row, Col, Container } from "react-bootstrap";
import KeyboardVoiceIcon from "@material-ui/icons/KeyboardVoice";
import Icon from "@material-ui/core/Icon";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import PaypalExpressBtn from "react-paypal-express-checkout";

import { paymentPaypalConfirmed } from "../../actions/userActions";

import { isMobile } from "react-device-detect";

export default class ConfirmOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagamentoSuccesso: null,
      pagamentoCancelado: null      
    };
  }

  render() {
    const onSuccess = payment => {
      paymentPaypalConfirmed(this.props.ID_Encomenda).then(data => {   
        this.setState({ pagamentoSuccesso: true });
      });
    };

    const onCancel = d => {
      this.setState({ pagamentoCancelado: true });
    };

    const onError = err => {
      console.log("Error!", err);
    };

    const client = {
      sandbox:
        "ARryo3EMtByfK7CEsi2oVl1EiM0U1t1ObdefdzsXr50uEldQ2x57AdBnipFTRp2nEWPMB5Aa4CXPkIvz",
      production:
        "AR5958tP2XMZQZaosxfqHOZ-AA2zbTAWN5WCoZVCZFRLsRFkM1lgMFOYw5Qa3f0_IY7tXm3cjHHDKv4Z"
    };

    return (
      <div style={{ display: this.props.show ? "block" : "none" }}>
        <br />
        <br />
        <h3>{this.props.encomendaComSucesso ? "Encomenda efetuada com sucesso" : "Não foi possível fazer a encomenda"}</h3>
        <br />

        <h5 style={{ textAlign: "justify", lineHeight: "3vh" }}>               
        {this.props.encomendaComSucesso ?
          !this.state.pagamentoCancelado
            ? !this.state.pagamentoSuccesso ? "Depois de confirmarmos com sucesso o seu pagamento irá receber um email para configurar o serviço Success Box."
              : "Parabéns, pagamento com sucesso. Irá receber um email para configurar o serviço Success Box."
            : "Pagamento cancelado pelo Paypal, volte a tentar ou contacte para obter ajuda."
        : <span>Ocorreu um erro a processar a sua encomenda. O artigo escolhido continuará no carrinho que pode consultar <a href='https://www.ihavethepower.net/Carrinho'>aqui</a>.</span>}</h5>
        <br />
        {this.props.ID_TipoPagamento === "PP" && this.props.encomendaComSucesso==true ? (
          !this.state.pagamentoSuccesso ? (
            <Row>
              <Col lg={12}>
                <PaypalExpressBtn
                  env={
                    process.env.NODE_ENV === "development"
                      ? "sandbox"
                      : "production"
                  }
                  client={client}
                  currency={"EUR"}
                  total={parseFloat(this.props.PrecoFinal)}
                  onError={onError}
                  onSuccess={onSuccess}
                  onCancel={onCancel}
                  style={{ color: "gold", zIndex: "1" }}
                />
              </Col>
            </Row>
          ) : null
        ) : null}

        {this.props.ID_TipoPagamento === "MB" && this.props.encomendaComSucesso==true ? (
          <Row>
            <Col
              lg={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
              <img
                src={
                  "https://doremon.ihavethepower.net/assets/ihtp-success-box/mb.jpg"
                }
                width={"100%"}
              />
            </Col>
            <Col
              lg={6}
              style={{
                textAlign: isMobile ? "center" : "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
              <span>
                <b>Referência:</b> {this.props.ReferenciaMB}
              </span>
              <br />
              <span>
                <b>Entidade:</b> {this.props.EntidadeMB}
              </span>
              <br />
              <span>
                <b>Valor:</b> {this.props.PrecoFinal} €
              </span>
            </Col>
          </Row>
        ) : null}
        <br />
        <h6>
          Caso tenha alguma dificuldade entre em contacto{" "}
          <a target="blank" href={"https://www.ihavethepower.net/Contacto"}>
            aqui
          </a>
        </h6>

        {/*<a
          target={"blank"}
          href={
            "http://localhost:3000/shop/payment/" + this.props.HashEncomenda
          }
        >
          Clique aqui para efetuar o pagamento
        </a>*/}
      </div>
    );
  }
}
