import React, { Component, Fragment } from "react";

export default class Hex extends React.Component {
  render() {
    return (
      <Fragment>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <br />
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <br />
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <br />
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <br />
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <br />
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <br />

        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        <div class="hex"></div>
        {/*<div class="spacer"></div> */}
      </Fragment>
    );
  }
}
