import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import DrawnForYouImage from "./DrawnForYouImage";
import DrawnForYouText from "./DrawnForYouText";

export default class DrawnForYou extends React.Component {
  render() {
    return (
      <Row style={{ maxWidth: "100vw" }}>
        <Col lg={12}>
          <Row style={{ textAlign: "center" }}>
            <Col lg={{ span: 8, offset: 2 }}>
              <h2 style={{ fontSize: isMobile ? "26px" : "2rem" }}>
                <b>{this.props.title}</b>
              </h2>
              <br />
              <h3 style={{ fontSize: isMobile ? "19px" : "1.75rem" }}>
                {this.props.description}
              </h3>
            </Col>
          </Row>
          <br />
          <br style={{ display: isMobile ? "none" : "block" }} />
          <Row>
            <Col
              lg={6}
              style={{
                textAlign: "right",
                marginBottom: isMobile ? "30px" : null
              }}
            >
              <DrawnForYouImage
                imageSrc={
                  "https://doremon.ihavethepower.net/assets/ihtp-success-box/Box2.png"
                }
              />
            </Col>
            <Col
              lg={5}
              style={{
                display: "flex",
                color: "rgb(3, 66, 117)",
                fontSize: isMobile ? "18px" : "22px",
                alignItems: "center",
                fontWeight: "bold",
                textAlign: "left"
              }}
            >
              <DrawnForYouText
                text1={"Sistema intuitivo e de fácil acesso"}
                text2={"Todo o conteúdo em português"}
                text3={"Novidades todos os meses"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
