import React, { Component, Fragment } from "react";

import { Row, Col } from "react-bootstrap";

export default class Hex extends React.Component {
  render() {
    return (
      <Row
        className={"HeaderPromotional"}
        id="top"
        style={{
          color: this.props.color,
          backgroundColor: this.props.backgroundColor
        }}
      >
        <Col lg={12}>{this.props.title}</Col>
      </Row>
    );
  }
}
