import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import HeroText from "./HeroText";
import HeroBarraHorizontal from "./HeroBarraHorizontal";
import HeaderImage from "./HeaderImage";
import HeroKnowMore from "./HeroKnowMore";

import Button from "@material-ui/core/Button";

import JourneyButton from "../Journey/JourneyButton";

import { Link } from "react-scroll";

export default class Hero extends React.Component {
  render() {
    return (
      <Row style={{ maxWidth: "100vw" }} id="top">
        <Col lg={12} style={{ padding: "0px" }}>
          <HeaderImage
            className={"hero-parallax-top"}
            imageSrc={
              "https://doremon.ihavethepower.net/assets/ihtp-success-box/clouds-fog-forest-9754.jpg"
            }
            amount={"0.5"}
            height={isMobile ? "50vh" : "50vh"}
          />
          <HeaderImage
            className={"hero-parallax-bottom"}
            imageSrc={
              "https://doremon.ihavethepower.net/assets/ihtp-success-box/adventure-asphalt-daylight-1955134.jpg"
            }
            amount={"0.5"}
            height={isMobile ? "50vh" : "50vh"}
          />
          <HeroBarraHorizontal background={"#efefef"} />

          <HeroText
            title={"IHTP SUCCESS BOX"}
            description1={""}
            description2={
              "Audiobooks de excelência pessoal para ter mais sucesso"
            }
          />

          <Col
            lg={12}
            style={{
              position: "absolute",
              top: isMobile ? "45vh" : "55vh",
              zIndex: 99999999999999
            }}
          >
            <JourneyButton
              variant={"outlined"}
              color={"white"}
              background={"#ca006a"}
              width={isMobile ? "70%" : "20%"}
              buttonText={"Subscreva agora"}
            />
          </Col>
          <HeroKnowMore text={"Descubra mais"} color={"white"} />
        </Col>
      </Row>
    );
  }
}
