import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import Fade from "react-reveal/Fade";
import { Link } from "react-scroll";

import Service from "./Service";
import BoxImage from "./BoxImage";
import BoxButton from "./BoxButton";

export default class BoxServices extends React.Component {
  render() {
    return (
      <Row style={{ maxWidth: "100vw" }} id="BoxServices">
        <Col lg={12}>
          <Row style={{ textAlign: "center" }}>
            <Col lg={{ span: 8, offset: 2 }}>
              <br />
              <br />
              <h2 style={{ fontSize: isMobile ? "26px" : "2rem" }}>
                <b>{this.props.title}</b>
              </h2>
              <br />
              <h3 style={{ fontSize: isMobile ? "19px" : "1.75rem" }}>
                {this.props.description}
              </h3>
            </Col>
          </Row>

          <br />
          <br />
          <br />
          <Row style={{ alignItems: "center" }}>
            <Col xs={12} sm={12} md={4} lg={4}>
              {/*SERVICE 1*/}
              <Service
                imageSrc={"https://image.flaticon.com/icons/svg/0/191.svg"}
                description1={"EM QUALQUER LUGAR"}
                description2={
                  "ACEDA AO CONTEÚDO ATRAVÉS DE QUALQUER DISPOSITIVO"
                }
                side={isMobile ? "right" : "left"}
              />
              <br />
              <br />
              {/*SERVICE 2*/}
              <Service
                imageSrc={"https://image.flaticon.com/icons/svg/61/61584.svg"}
                description1={"BAIXO INVESTIMENTO"}
                description2={
                  "TODO O CONTEÚDO POR UM VALOR FANTÁSTICO E COM ATUALIZAÇÕES"
                }
                side={isMobile ? "left" : "left"}
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={4}
              lg={4}
              style={{ marginBottom: "15px", marginTop: "15px" }}
            >
              {/*IMAGEM DOS SERVICES*/}
              <BoxImage
                imageSrc={
                  "https://doremon.ihavethepower.net/assets/ihtp-success-box/Box1.png"
                }
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              {/*SERVICE 3*/}
              <Service
                imageSrc={
                  "https://image.flaticon.com/icons/svg/1077/1077982.svg"
                }
                description1={"OFERTA PERSONALIZADA"}
                description2={
                  "CONTEÚDO DIVERSIFICADO E AJUSTADO ÀS SUAS NECESSIDADES"
                }
                side={isMobile ? "right" : "right"}
              />
              <br />
              <br />
              {/*SERVICE 4*/}
              <Service
                imageSrc={"https://image.flaticon.com/icons/svg/149/149646.svg"}
                description1={"APROVEITE O SEU TEMPO"}
                description2={
                  "INSPIRE-SE TODOS OS DIAS OUVINDO OS NOSSOS AUDIOBOOKS, ONDE E QUANDO QUISER"
                }
                side={isMobile ? "left" : "right"}
              />
            </Col>
          </Row>
          <br />
          <br />
          {/*BOX BUTTON*/}
          <Link
            activeClass="active"
            to="Pricing"
            spy={true}
            smooth={true}
            duration={2000}
          >
            <BoxButton
              variant={"outlined"}
              color={"white"}
              background={"#ca006a"}
              width={isMobile ? "70%" : "15%"}
              buttonText={"Comece já"}
            />
          </Link>
        </Col>
      </Row>
    );
  }
}
