import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import Fade from "react-reveal/Fade";
import { Link } from "react-scroll";

import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import FaceIcon from "@material-ui/icons/Face";

export default class TemasAudiobooks extends React.Component {
  render() {
    return (
      <Row style={{ maxWidth: "100vw" }} id="BoxServices">
        <Col lg={12}>
          <br />
          <h2 style={{ fontWeight: "bold" }}>
            Temas dos audiobooks a que vai ter acesso
          </h2>
          <br />
          {this.props.temas.map(tema => {
            return (
              <Chip
                style={{ margin: "10px" }}
                icon={<DoneIcon />}
                label={tema.descricao}
                clickable
                color="primary"
                variant="outlined"
              />
            );
          })}
        </Col>
      </Row>
    );
  }
}
