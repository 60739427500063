import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.scss";
import Fade from "react-reveal/Fade";
import { Link } from "react-scroll";
import { isMobile } from "react-device-detect";

import { ParallaxBanner } from "react-scroll-parallax";

import { Container, Row, Col } from "react-bootstrap";

import { Helmet } from "react-helmet";

/* Para gerar css para hexagonos: http://brenna.github.io/csshexagon/ */
/* Hexagon effects: https://freefrontend.com/css-hexagons/ */
/* CSS Patterns: heropatterns.com */
/* Hero Icons: http://www.heroicons.com/ */
/* https://bennettfeely.com/clippy/ */
/* Style CSS: https://blog.bitsrc.io/5-ways-to-style-react-components-in-2019-30f1ccc2b5b */

/* Hero Hex */
import Hex from "./Hex";

import HeaderPromotional from "./components/HeaderPromotional";
import Parallax from "./components/Parallax";

import Login from "./components/Login";
import Register from "./components/Register";
import Pricing from "./components/Pricing";
import Checkout from "./components/Checkout";
import ScrollTop from "./components/ScrollTop";
import Hero from "./components/Hero";
import BoxServices from "./components/BoxServices";
import Advantages from "./components/Advantages";
import DrawnForYou from "./components/DrawnForYou";
import Journey from "./components/Journey";

import TemasAudiobooks from "./components/TemasAudiobooks";

import $ from "jquery";

import { instanceOf } from "prop-types";

import { withCookies, Cookies } from "react-cookie";

import { getMoradasFaturacao } from "./actions/userActions";

import TagManager from "react-gtm-module";

$(window).scroll(function() {
  if ($(this).scrollTop() > 130) {
    $(".scrollToTop").fadeIn();
  } else {
    $(".scrollToTop").fadeOut();
  }
});

var uniqid = require("uniqid");

function getAllUrlParams(url) {
  // get query string from url (optional) or window
  var queryString = url ? url.split("?")[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split("#")[0];

    // split our query string into its component parts
    var arr = queryString.split("&");

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split("=");

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof a[1] === "undefined" ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === "string") paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, "");
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === "string") {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}

class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = this.props;
    this.state = {
      loginDisplay: false,
      registoDisplay: false,
      checkoutDisplay: false,
      id_pack: null,
      moradas: [],
      novaMoradaDisplay: false,
      ID_ProdutoSelecionado: null,
      DescriptionProdutoSelecionado: null,
      showLogin: false,
      id_pack_selecionado: null

      /*subscricoes: [
        {
          ID_Produto: process.env.NODE_ENV === "development" ? 851 : 848,
          Description: "Power"
        },
        {
          ID_Produto: process.env.NODE_ENV === "development" ? 852 : 849,
          Description: "Power Plus"
        },
        { ID_Produto: 850, Description: "Excellence" }
      ]*/
    };
    this.changeLoginDisplay = this.changeLoginDisplay.bind(this);

    this.changeRegistoDisplay = this.changeRegistoDisplay.bind(this);
    this.changeCheckoutDisplay = this.changeCheckoutDisplay.bind(this);
    this.updatePack = this.updatePack.bind(this);
    this.updateMoradas = this.updateMoradas.bind(this);
    this.changeNovaMoradaDisplay = this.changeNovaMoradaDisplay.bind(this);

    /* Mostrar Login - 24-09-2019 - Jean-Pierre Carvalho */
    this.showCheckout = this.showCheckout.bind(this);
    this.hideCheckout = this.hideCheckout.bind(this);

    // Para fazer set ao refer
    if (getAllUrlParams(window.location.search).refer) {
      cookies.set("refer", getAllUrlParams(window.location.search).refer, {
        path: "/"
      });
    }
  }

  showCheckout(id_pack) {
    this.setState({ id_pack_selecionado: id_pack });
    this.setState({ showLogin: true });
  }

  hideCheckout() {
    this.setState({ showLogin: false });
  }

  changeLoginDisplay() {
    this.setState({ loginDisplay: true });
  }

  changeRegistoDisplay() {
    this.setState({ registoDisplay: !this.state.registoDisplay });
  }

  changeCheckoutDisplay() {
    this.setState({ checkoutDisplay: !this.state.checkoutDisplay });
  }

  updatePack(ID_Pack, Description) {
    // Add Window DataLayer
    window.dataLayer.push({
      event: "PricingSubscriptionBtnEvent",
      category: "AddSubscriptionPricing",
      action: "Click",
      label: Description
    });

    this.setState({ id_pack: ID_Pack });
    this.setState({ ID_ProdutoSelecionado: ID_Pack });
    this.setState({ DescriptionProdutoSelecionado: Description });
  }

  updateMoradas() {
    getMoradasFaturacao().then(data => {
      if (data[0]) {
        this.setState({ novaMoradaDisplay: false });
        this.setState({ moradas: data });
      } else {
        this.setState({ novaMoradaDisplay: true });
      }
    });
  }

  changeNovaMoradaDisplay() {
    this.setState({ novaMoradaDisplay: !this.state.novaMoradaDisplay });
  }

  render() {
    // Tag Manager ID
    const tagManagerArgs = {
      gtmId:
        process.env.NODE_ENV === "development" ? "GTM-NJ4GGBH" : "GTM-MMV7WR6",
      dataLayer: {
        //userId: "001",
        //userProject: "project",
        page: "successBox"
        /*,
        auth: "6sBOnZx1hqPcO01xPOytLK",
        preview: "env-2"*/
        /*events: {
          sendUserInfo: "userInfo"
        }*/
      }
    };
    // TagManager.dataLayer(tagManagerArgs)
    // Iniciar o Google Tag Manager
    TagManager.initialize(tagManagerArgs);
    return (
      <div className="App">
        <Helmet>
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="robots" content="index,follow" />
          <title>Success Box | I Have The Power</title>
          <meta
            name="description"
            content="A Success Box é a melhor oferta de soluções para o desenvolvimento pessoal. Subscreva o nosso plano Power, Power Plus ou Excellence para obter os melhores audiobooks nesta área em português."
          />
          <meta
            name="keywords"
            content="Success Box, I Have The Power, Power, Power Plus, Excellence, Adelino Cunha, Tony Robbins, Desenvolvimento Pessoal, Audiobooks, Português, Soluções, PNL, Coaching"
          />
          <link rel="canonical" href="https://successbox.ihavethepower.com/" />
        </Helmet>
        <Container>
          {/* Header Promotional
          <HeaderPromotional
            title={
              "Aproveite só hoje 20% na sua box, tempo limitado. Use este código."
            }
            backgroundColor="#ca006a"
            color="white"
          />*/}
          {/* Hero */}
          <Hero />
          {/* Serviços da caixa */}

          <br />
          <br />
          <Fade top>
            <Advantages
              title={"Há decisões de valor incalculável"}
              description={"Vale a pena aproveitar esta oportunidade"}
            />
          </Fade>

          {/* Parallax 1 */}
          <br />

          <br />
          <Parallax
            imageSrc={
              "https://doremon.ihavethepower.net/assets/ihtp-success-box/clouds-fog-forest-9754.jpg"
            }
            amount={0.5}
            height={"500px"}
            className={"your-class"}
          />
          <br />
          <TemasAudiobooks
            temas={[
              { descricao: "Desenvolvimento Pessoal" },
              { descricao: "Definição de Objectivos" },
              { descricao: "Planeamento" },
              { descricao: "Gestão de Tempo" },
              { descricao: "Liderança de equipas" },
              { descricao: "Tratar conflitos" },
              { descricao: "Motivação" },
              { descricao: "Tratar Objecções" },
              { descricao: "Fechar vendas" },
              { descricao: "Gerir emoções" },
              { descricao: "Relacionamentos" },
              { descricao: "Educação de filhos" },
              { descricao: "Procrastinação" },
              { descricao: "Alinhamento de valores" },
              { descricao: "Felicidade" },
              { descricao: "Coaching" },
              { descricao: "Programação Neuro-Linguistica" }
            ]}
          />
          <br />
          {/* Principais vantagens */}
          <Fade top>
            <BoxServices
              title={"Tudo o que precisa para ter o sucesso que merece"}
              description={
                "Vai poder aceder a todos os nossos audiobooks, através da sua subscrição mensal ou anual, podendo receber gratuitamente todas as atualizações para que possa aproveitar ao máximo do melhor que temos para si"
              }
            />
          </Fade>
          {/* Parallax 2 */}
          <br />
          <br />
          <Parallax
            imageSrc={
              "https://doremon.ihavethepower.net/assets/ihtp-success-box/adventure-balance-balanced-7640.jpg"
            }
            amount={0.5}
            height={"500px"}
            className={"your-class"}
          />
          <br />
          <br />
          {/* Um projeto desenhado para si */}
          <Fade top>
            <DrawnForYou
              title={"Um projecto desenhado para si"}
              description={
                "Ferramentas exclusivas para quem quer evoluir e realizar os seus sonhos"
              }
            />
          </Fade>
          {/* Parallax 3 */}
          <br />
          <br />
          <Parallax
            imageSrc={
              "https://doremon.ihavethepower.net/assets/ihtp-success-box/break-car-couple-196666.jpg"
            }
            amount={0.5}
            height={"500px"}
            className={"your-class"}
          />
          <br />

          {/* Pricing */}

          <Pricing
            title={"O melhor investimento"}
            description={
              "Um valor irresistível para si e para as pessoas a quem pode recomendar"
            }
            ID_Pack_1={process.env.NODE_ENV === "development" ? 851 : 848}
            ID_Pack_2={process.env.NODE_ENV === "development" ? 852 : 849}
            ID_Pack_3={850}
            altText={
              "* pode receber os seus ganhos ou investir na sua formação em produtos, livros e ferramentas, formação e coaching IHTP"
            }
            altTextFontSize={"14px"}
            altTextColor={"#ca006a"}
            altTextAlign={"right"}
            updatePack={this.updatePack}
            showCheckout={this.showCheckout}
          />
          <br />
          <br />
          <Checkout
            showLogin={this.state.showLogin}
            hideCheckout={this.hideCheckout}
            showCheckout={this.showCheckout}
            id_pack_selecionado={this.state.id_pack_selecionado}
          />

          {/* Parallax 4 */}
          <br />
          <br />
          <Parallax
            imageSrc={
              "https://doremon.ihavethepower.net/assets/ihtp-success-box/adventure-automobile-classic-2533090.jpg"
            }
            amount={0.5}
            height={"500px"}
            className={"your-class"}
          />
          <br />
          <br />
          {/* Login */}
          <Fade top>
            <Login
              title={"Já tem conta?"}
              display={this.state.loginDisplay}
              changeLoginDisplay={this.changeLoginDisplay}
              changeRegistoDisplay={this.changeRegistoDisplay}
              changeCheckoutDisplay={this.changeCheckoutDisplay}
              updateMoradas={this.updateMoradas}
            />
          </Fade>
          {/* Registo */}
          <Fade top>
            <Register
              title={"Ainda não tem conta? Registe-se!"}
              display={this.state.registoDisplay}
              changeLoginDisplay={this.changeLoginDisplay}
              changeRegistoDisplay={this.changeRegistoDisplay}
              changeCheckoutDisplay={this.changeCheckoutDisplay}
              updateMoradas={this.updateMoradas}
            />
          </Fade>
          {/* Registo */}
          {/*<Fade top>
            <Checkout
              ID_Pack={this.state.ID_Pack}
              title={"Encomendar"}
              display={this.state.checkoutDisplay}
              id_pack={this.state.id_pack}
              moradas={this.state.moradas}
              novaMoradaDisplay={this.state.novaMoradaDisplay}
              changeNovaMoradaDisplay={this.changeNovaMoradaDisplay}
              DescriptionProdutoSelecionado={
                this.state.DescriptionProdutoSelecionado
              }
              ID_ProdutoSelecionado={this.state.ID_ProdutoSelecionado}
            />
          </Fade>*/}
          <br />
          <br />
          <Fade top>
            {/* Journey */}
            <Journey
              display={!this.state.loginDisplay}
              text={"A sua vida já começou a mudar"}
            />
          </Fade>
          <br />
          <br />
          <ScrollTop />
        </Container>
      </div>
    );
  }
}

export default withCookies(App);
