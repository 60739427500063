import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { comprarIHTPSUCCESS } from "../../actions/homeActions";
import { scroller } from "react-scroll";

import { Link } from "react-scroll";

export default class PricingElement extends React.Component {
  render() {
    return (
      <Col xs={12} sm={12} md={4} lg={4}>
        <div
          className={
            this.props.active
              ? "generic_content active clearfix"
              : "generic_content clearfix"
          }
        >
          <div className="generic_head_price clearfix">
            <div className="generic_head_content clearfix">
              <div className="head_bg"></div>
              <div className="head">
                <span>{this.props.title}</span>
              </div>
            </div>

            <div className="generic_price_tag clearfix">
              <span className="price">
                <span className="currency">
                  <s>{this.props.earlierPrice}</s>
                </span>
              </span>
              <span className="price">
                <span className="currency">{this.props.currency}</span>
                <span className="cent">{this.props.cent}</span>
                <span className="month">/{this.props.subscriptionType}</span>
              </span>
            </div>
          </div>

          <div className="generic_feature_list">
            <ul>
              {/*Pode-se usar o span para que fique a negrito*/}
              {this.props.conditions.map(condition => {
                return condition.active ? (
                  <li>
                    <span style={{ color: "#ca006a" }}>{condition.text}</span>
                  </li>
                ) : (
                  <li>{condition.text}</li>
                );
              })}
            </ul>
          </div>
          <div className="generic_price_btn clearfix">
            <Link
              activeClass="active"
              to="checkout"
              spy={true}
              smooth={true}
              duration={1300}
            >
              <a
                className="subscribeButton"
                style={{ border: "none" }}
                onClick={() => {
                  this.props.updatePack(
                    this.props.ID_Pack,
                    this.props.button_ID
                  );
                  this.props.showCheckout(this.props.ID_Pack);
                  /*setTimeout(
                  scroller.scrollTo("login", {
                    duration: 1300,
                    delay: 0,
                    smooth: "easeInOutQuart"
                  }),
                  1200
                );*/
                }}
              >
                {this.props.buttonText}
              </a>
            </Link>
          </div>
        </div>
      </Col>
    );
  }
}
