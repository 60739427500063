import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import Button from "@material-ui/core/Button";
import JourneyButton from "./JourneyButton";

export default class Journey extends React.Component {
  render() {
    return (
      <Row
        style={{
          maxWidth: "100vw",
          minHeight: "15vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Col lg={12}>
          <h2>
            <b>{this.props.text}</b>
          </h2>
        </Col>
        <Col lg={12} style={{ display: this.props.display ? "block" : "none" }}>
          <JourneyButton
            variant={"outlined"}
            color={"white"}
            background={"#ca006a"}
            width={isMobile ? "70%" : "20%"}
            buttonText={"Comece aqui a sua jornada"}
          />
        </Col>
      </Row>
    );
  }
}
