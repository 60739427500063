import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core/Radio";
import { Formik } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { adicionarPack, checkout, getTiposPagamento } from "../../actions/userActions";

export default class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagamentoSelecionado: false,
      pagamentos: []
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    getTiposPagamento().then(data => {
      console.log(data);
      this.setState({
        pagamentos: data
      });
    });
  }

  handleChange(e) {
    console.log(e.target.value);
    this.setState({
      pagamentoSelecionado: e.target.value
    });
  }

  render() {
    return (
      <Formik
        /* Dados a enviar para a API */
        initialValues={{}}
        onSubmit={(values, { setSubmitting }) => {
          var checkoutData = {
            ID_TipoPagamento: this.state.pagamentoSelecionado,
            ID_Cliente_Morada: this.props.moradaSelecionada.ID_Cliente_Morada
          };
          // Guardar produtos que se encontram no Carrinho
          // Remover produtos do Carrinho
          // Adicionar subscricao ao Carrinho
          // Gerar encomenda
          // Adicionar produtos anterior no carrinho
          var that = this;
          if (!this.state.pagamentoSelecionado) {
            alert("Insira um método de pagamento");
            setSubmitting(false);
          } else {
            adicionarPack(this.props.id_pack).then(function (data) {
              checkout(checkoutData).then(function (data) {
                
                // Se chegar uma mensagem de erro do servidor
                if (data.MensagemErro) {
                  that.props.couldConfirmOrder(false);

                  // Se conseguirmos fazer a encomenda
                } else {

                  that.props.getPaymentCall(
                    data.ID_Encomenda,
                    data.HashEncomenda
                  );
                  that.props.getOrderPaymentCall(data.HashEncomenda);
                  that.props.couldConfirmOrder(true);
                }

                //Isto tira o loading do botão
                setSubmitting(false);
              });
            });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
          /* and other goodies */
        }) => (
            <form
              onSubmit={handleSubmit}
              style={{ display: this.props.show ? "block" : "none" }}
            >
              <br />
              <Row style={{}}>
                <Col lg={12} style={{ textAlign: "left" }}>
                  <a
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={this.props.showBilling}
                  >
                    {"<"} Voltar
                </a>
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: "3vh",
                  marginBottom: "3vh"
                }}
              >
                <Col lg={12}>Métodos de pagamento</Col>
              </Row>
              <Row>
                <Col lg={6}>
                  Paypal{" "}
                  <Radio
                    checked={this.state.pagamentoSelecionado === "PP"}
                    onChange={this.handleChange}
                    value={"PP"}
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "PP" }}
                  />
                </Col>
                <Col lg={6}>
                  Multibanco{" "}
                  <Radio
                    checked={this.state.pagamentoSelecionado === "MB"}
                    onChange={this.handleChange}
                    value={"MB"}
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "MB" }}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col lg={12}>
                  {" "}
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={isSubmitting}
                    fullWidth
                  >
                    {isSubmitting ? <CircularProgress /> : "Efetuar encomenda"}
                  </Button>
                </Col>
              </Row>
            </form>
          )}
      </Formik>
    );
  }
}
