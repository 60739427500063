import React, { Component, Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import Button from "@material-ui/core/Button";
import { Row, Col, Container } from "react-bootstrap";
import KeyboardVoiceIcon from "@material-ui/icons/KeyboardVoice";
import Icon from "@material-ui/core/Icon";

import { addAddress, getPaises } from "../../actions/userActions";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import CircularProgress from "@material-ui/core/CircularProgress";

function validateNIF(value) {
  const nif = typeof value === "string" ? value : value.toString();
  const validationSets = {
    one: ["1", "2", "3", "5", "6", "8"],
    two: [
      "45",
      "70",
      "71",
      "72",
      "74",
      "75",
      "77",
      "79",
      "90",
      "91",
      "98",
      "99"
    ]
  };

  if (nif.length !== 9) {
    return false;
  }

  if (
    !validationSets.one.includes(nif.substr(0, 1)) &&
    !validationSets.two.includes(nif.substr(0, 2))
  ) {
    return false;
  }

  let total =
    nif[0] * 9 +
    nif[1] * 8 +
    nif[2] * 7 +
    nif[3] * 6 +
    nif[4] * 5 +
    nif[5] * 4 +
    nif[6] * 3 +
    nif[7] * 2;
  let modulo11 = Number(total) % 11;

  const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11;

  return checkDigit === Number(nif[8]);
}

export default class NewAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paises: []
      //newAddressShow: true,
    };
  }

  componentWillMount() {
    getPaises().then(data => {
      this.setState({
        paises: data
      });
    });
  }

  render() {
    return (
      <div>
        <Formik
          /* Dados a enviar para a API */
          initialValues={{
            nome: "",
            morada: "",
            id_pais: "PRT",
            localidade: "",
            codigo_postal: "",
            nif: ""
          }}
          onSubmit={(values, { setSubmitting }) => {
            var that = this;
            if (!validateNIF(values.nif)) {
              alert("Insira um nif válido");
              setSubmitting(false);
            } else {
              addAddress(values).then(data => {
                if (data.message === "Sucesso ao adicionar a sua morada.") {
                  this.props.getMoradasFaturacaoCall();
                  this.props.showBilling();
                  setSubmitting(false);
                } else {
                  setSubmitting(false);
                }
              });
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
            /* and other goodies */
          }) => (
            <form
              onSubmit={handleSubmit}
              style={{
                display: this.props.show ? "block" : "none"
              }}
            >
              <br />
              <br />
              <Row>
                <Col lg={12} style={{ textAlign: "left" }}>
                  <a
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={this.props.showBilling}
                  >
                    {"<"} Voltar
                  </a>
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: "3vh",
                  marginBottom: "3vh"
                }}
              >
                <Col lg={12}>Adicione outra morada de faturação</Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Nome"
                    defaultValue=""
                    margin="normal"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nome}
                    fullWidth
                    type="text"
                    name="nome"
                    error={errors.nome}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Morada"
                    defaultValue=""
                    margin="normal"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.morada}
                    fullWidth
                    type="text"
                    name="morada"
                    error={errors.morada}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  lg={4}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                  }}
                >
                  <FormControl fullWidth>
                    <Select
                      value={values.id_pais}
                      onChange={handleChange}
                      inputProps={{
                        name: "id_pais"
                      }}
                    >
                      {this.state.paises.map(function(x) {
                        return <MenuItem value={x.alpha3}>{x.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Col>
                <Col lg={4}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Localidade"
                    defaultValue=""
                    margin="normal"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.localidade}
                    fullWidth
                    type="text"
                    name="localidade"
                    error={errors.localidade}
                  />
                </Col>
                <Col lg={4}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Código Postal"
                    defaultValue=""
                    margin="normal"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.codigo_postal}
                    fullWidth
                    type="text"
                    name="codigo_postal"
                    error={errors.codigo_postal}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Nif"
                    defaultValue=""
                    margin="normal"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nif}
                    fullWidth
                    type="text"
                    name="nif"
                    error={errors.nif}
                  />
                </Col>
              </Row>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress /> : "Adicionar nova morada"}
              </Button>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}
