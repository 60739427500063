import React, { Component, Fragment } from "react";
import { Input, Spinner } from "reactstrap";
import { Row, Col, Container } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {
  getTiposPagamento,
  getPaises,
  addAddress,
  checkout,
  getMoradasFaturacao,
  getMorada,
  getPackPrice
} from "../../actions/userActions";
import { comprarIHTPSUCCESS } from "../../actions/homeActions";

import KeyboardVoiceIcon from "@material-ui/icons/KeyboardVoice";
import Icon from "@material-ui/core/Icon";

import Login from "./Login";
import Register from "./Register";
import RecoverPassword from "./RecoverPassword";
import AddProduct from "./AddProduct";
import Billing from "./Billing";
import NewAddress from "./NewAddress";

import Payment from "./Payment";
import ConfirmOrder from "./ConfirmOrder";

import Avatar from "react-avatar";

import { getOrderPayment } from "../../actions/userActions";

export default class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Show registerFields
      registerFieldsState: false,

      recoverPasswordFieldsState: false,
      // Show productFields
      productFieldsState: false,
      // Show billingFields
      billingFieldsState: false,
      newAddressFieldState: false,
      // Show addBillingFieldsState
      addBillingFieldsState: false,
      // Show paymentFields
      paymentFieldsState: false,
      confirmOrderShow: false,
      // Para efetuar encomenda
      id_cliente_morada: null,
      preçoPackEscolhido: 25,
      checkout: {},
      loading: false,
      loginEmail: "",
      loginMessage: "",
      ID_Encomenda: null,
      ID_TipoPagamento: null,
      HashEncomenda: null,
      EntidadeMB: null,
      ReferenciaMB: null,
      PrecoFinal: null,
      moradas: [],
      moradaSelecionada: {
        ID_Cliente_Morada: null
      },
      encomendaComSucesso:false,
      precoPackPower: 0,
      precoPackPowerPlus: 0,
      precoPackExcellence: 0
    };

    this.getSubscribedPack = this.getSubscribedPack.bind(this);

    this.getLoginCallMessage = this.getLoginCallMessage.bind(this);
    this.showLogin = this.showLogin.bind(this);
    this.showRegister = this.showRegister.bind(this);
    this.showAddToCart = this.showAddToCart.bind(this);
    this.showBilling = this.showBilling.bind(this);
    this.showNewAddress = this.showNewAddress.bind(this);

    this.getAddToCartCall = this.getAddToCartCall.bind(this);
    this.getAddBillingAddressCall = this.getAddBillingAddressCall.bind(this);
    // Obter message do pagamento
    this.getPaymentCall = this.getPaymentCall.bind(this);
    // Obter order de pagamento
    this.getOrderPaymentCall = this.getOrderPaymentCall.bind(this);

    this.couldConfirmOrder=this.couldConfirmOrder.bind(this);

    this.getMoradasFaturacaoCall = this.getMoradasFaturacaoCall.bind(this);

    this.getLastAddress = this.getLastAddress.bind(this);

    this.handleChangeAddress = this.handleChangeAddress.bind(this);

    this.getPrices = this.getPrices.bind(this);
  }

  componentWillMount() {
    this.getMoradasFaturacaoCall();
  }

  chechoutCompra = async e => {
    this.setState({ loading: true });
    comprarIHTPSUCCESS(this.props.ID_ProdutoSelecionado).then(data => {
      if (data === "Cart updated succesful") {
        window.location =
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000" + "/Carrinho"
            : "https://www.ihavethepower.net" + "/Carrinho";
      } else {
        this.setState({ loading: false });
        window.alert("Erro ao adicionar subscrição ao carrinho");
      }
    });
  };

  // Obter pack Subscrito
  getSubscribedPack(ID_Pack) {
    this.setState({ id_pack: ID_Pack });
  }

  couldConfirmOrder(orderWasConfirmed){
    this.setState({ encomendaComSucesso: orderWasConfirmed})
    this.setState({ confirmOrderShow: true });
  }

  // Obter dados do pedido de login
  getLoginCallMessage(email, message) {
    // Guardar messagem na variavel loginMessage
    this.setState({ loginMessage: message });
    // Se for efetuado com sucesso
    if (message === "Login efetuado com sucesso") {
      this.setState({ loginEmail: email });
      // Esconde o login
      this.props.hideCheckout();
      // Esconder registar
      this.setState({ registerFieldsState: false });
      // Mostrar o add to cart
      this.setState({ productFieldsState: true });
    }
  }

  // Obter dados do pedido do add address
  showLogin() {
    this.props.showCheckout();
    this.setState({ registerFieldsState: false });
    this.setState({ recoverPasswordFieldsState: false });
    this.setState({ billingFieldsState: false });
    this.setState({ newAddressFieldState: false });
    this.setState({ productFieldsState: false });
    this.setState({ paymentFieldsState: false });
    // Limpar moradas
    this.setState({
      moradas: []
    });
    this.setState({
      moradaSelecionada: {}
    });
  }

  // Obter dados do pedido do add address
  showRegister() {
    this.props.hideCheckout();
    this.setState({ registerFieldsState: true });
    this.setState({ recoverPasswordFieldsState: false });
    this.setState({ billingFieldsState: false });
    this.setState({ newAddressFieldState: false });
    this.setState({ productFieldsState: false });
    this.setState({ paymentFieldsState: false });
    // Limpar moradas
    this.setState({
      moradas: []
    });
    this.setState({
      moradaSelecionada: {}
    });
  }

  // Obter dados do pedido do add address
  showAddToCart() {
    this.props.hideCheckout();
    this.setState({ recoverPasswordFieldsState: false });
    this.setState({ registerFieldsState: false });
    this.setState({ billingFieldsState: false });
    this.setState({ newAddressFieldState: false });
    this.setState({ productFieldsState: true });
    this.setState({ paymentFieldsState: false });
  }

  // Obter dados do pedido do add address
  showBilling() {
    this.props.hideCheckout();
    this.setState({ recoverPasswordFieldsState: false });
    this.setState({ registerFieldsState: false });
    this.setState({ billingFieldsState: true });
    this.setState({ newAddressFieldState: false });
    this.setState({ productFieldsState: false });
    this.setState({ paymentFieldsState: false });
  }

  showNewAddress() {
    this.props.hideCheckout();
    this.setState({ recoverPasswordFieldsState: false });
    this.setState({ registerFieldsState: false });
    this.setState({ billingFieldsState: false });
    this.setState({ newAddressFieldState: true });
    this.setState({ productFieldsState: false });
    this.setState({ paymentFieldsState: false });
  }

  // Obter dados do pedido do add To cart
  getAddToCartCall(product) {
    // Mostrar o add to cart
    this.setState({ billingFieldsState: true });
    // Esconder o add to cart
    this.setState({ productFieldsState: false });
    //Pack Escolhido
    this.setState({ packEscolhido: product });
  }

  // Obter dados do pedido do add address
  getAddBillingAddressCall(message) {
    this.setState({ billingFieldsState: false });
    this.setState({ paymentFieldsState: true });
  }

  // Obter dados do pedido do payment
  getPaymentCall(ID_Encomenda, HashEncomenda) {
    this.setState({ ID_Encomenda: ID_Encomenda });
    this.setState({ HashEncomenda: HashEncomenda });
    this.setState({ paymentFieldsState: false });
    this.setState({ confirmOrderShow: true });
  }

  getOrderPaymentCall(HashEncomenda) {
    getOrderPayment(HashEncomenda)
      .then(data => {
        this.setState({ ID_TipoPagamento: data.data[0].ID_TipoPagamento });
        this.setState({ EntidadeMB: data.data[0].EntidadeMB });
        this.setState({ ReferenciaMB: data.data[0].ReferenciaMB });
        this.setState({ PrecoFinal: data.data[0].PrecoFinal });
      })
      .catch(err => {
        console.log(err);
      });
  }

  getMoradasFaturacaoCall() {
    getMoradasFaturacao().then(data => {
      this.setState({
        moradas: data
      });
      if (this.state.moradas.length > 0) {
        this.getLastAddress();
      }
      // Guardar morada selecionada, automaticamente
    });
  }

  getLastAddress() {
    var lastAddress =
      this.state.moradas.length > 0
        ? this.state.moradas[this.state.moradas.length - 1].ID_ClienteMorada
        : null;
    getMorada(lastAddress).then(data => {
      this.setState({
        moradaSelecionada: data
      });
    });
  }

  handleChangeAddress(e) {
    getMorada(e.target.value).then(data => {      
      this.setState({
        moradaSelecionada: data
      });
    });
  }

  getPrices() {
    var that = this;
    getPackPrice(850)
      .then(function(data) {
        console.log(data.data.detalhesPack[0].preco);
        that.setState({
          precoPackExcellence: data.data.detalhesPack[0].preco + " €"
        });
      })
      .catch(err => {
        console.log(err);
      });
    getPackPrice(process.env.NODE_ENV === "development" ? 851 : 848)
      .then(function(data) {
        console.log(data.data.detalhesPack[0].preco);
        that.setState({
          precoPackPower: data.data.detalhesPack[0].preco + " €"
        });
      })
      .catch(err => {
        console.log(err);
      });
    getPackPrice(process.env.NODE_ENV === "development" ? 852 : 849)
      .then(function(data) {
        console.log(data.data.detalhesPack[0].preco);
        that.setState({
          precoPackPowerPlus: data.data.detalhesPack[0].preco + " €"
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    return (
      <Row id="checkout">
        <Col lg={4}></Col>
        <Col lg={4}>
          {/*<Row
            style={{
              marginTop: "3vh",
              marginBottom: "3vh"
            }}
          >
            <Col lg={2}>
              <Avatar name="CTO Digipulse" />
            </Col>
            <Col lg={4} style={{ textAlign: "left" }}>
              <span>{this.state.loginEmail}</span>
              <br />
              <span>Logout</span>
            </Col>
            <Col lg={6} style={{ textAlign: "left" }}>
              {this.state.packEscolhido ? (
                <div>
                  <span>
                    Subscrição Selecionada: {this.state.packEscolhido}
                  </span>
                  <br />
                  <span>Preço: {this.state.preçoPackEscolhido}</span>
                </div>
              ) : null}
            </Col>
          </Row>*/}
          <Login
            show={this.props.showLogin}
            getLoginCallMessage={this.getLoginCallMessage}
            showRegister={this.showRegister}
            getMoradasFaturacaoCall={this.getMoradasFaturacaoCall}
            getPrices={this.getPrices}
          />
          <Register
            show={this.state.registerFieldsState}
            getRegisterCall={this.getRegisterCall}
            showLogin={this.showLogin}
            getLoginCallMessage={this.getLoginCallMessage}
            getMoradasFaturacaoCall={this.getMoradasFaturacaoCall}
            getPrices={this.getPrices}
          />
          <RecoverPassword
            show={this.state.recoverPasswordFieldsState}
            showLogin={this.showLogin}
            showRegister={this.showRegister}
          />
          <AddProduct
            show={this.state.productFieldsState}
            getAddToCartCall={this.getAddToCartCall}
            getSubscribedPack={this.getSubscribedPack}
            showLogin={this.showLogin}
            id_pack_selecionado={this.props.id_pack_selecionado}
            precoPackPower={this.state.precoPackPower}
            precoPackPowerPlus={this.state.precoPackPowerPlus}
            precoPackExcellence={this.state.precoPackExcellence}
          />
          <Billing
            show={this.state.billingFieldsState}
            getAddBillingAddressCall={this.getAddBillingAddressCall}
            showAddToCart={this.showAddToCart}
            showNewAddress={this.showNewAddress}
            moradas={this.state.moradas}
            moradaSelecionada={this.state.moradaSelecionada}
            handleChangeAddress={this.handleChangeAddress}
          />
          <NewAddress
            show={this.state.newAddressFieldState}
            showBilling={this.showBilling}
            getMoradasFaturacaoCall={this.getMoradasFaturacaoCall}
          />
          <Payment
            show={this.state.paymentFieldsState}
            getPaymentCall={this.getPaymentCall}
            id_pack={this.state.id_pack}
            id_cliente_morada={this.state.id_cliente_morada}
            showBilling={this.showBilling}
            getOrderPaymentCall={this.getOrderPaymentCall}
            moradaSelecionada={this.state.moradaSelecionada}
            couldConfirmOrder={this.couldConfirmOrder}
          />
          <ConfirmOrder
            encomendaComSucesso={this.state.encomendaComSucesso}
            show={this.state.confirmOrderShow}
            ID_Encomenda={this.state.ID_Encomenda}
            HashEncomenda={this.state.HashEncomenda}
            ID_TipoPagamento={this.state.ID_TipoPagamento}
            EntidadeMB={this.state.EntidadeMB}
            ReferenciaMB={this.state.ReferenciaMB}
            PrecoFinal={this.state.PrecoFinal}
          />
        </Col>
        <Col lg={4}></Col>
      </Row>
    );
  }
}

{
  /*<Row style={{ textAlign: "center" }}>
  <Col lg={{ span: 10, offset: 1 }}>
    <br />
    <br />
    <h2 style={{ fontSize: isMobile ? "26px" : "2rem" }}>
      <b>{this.props.title}</b>
    </h2>
    <br />
  </Col>
</Row>
<Container>
  <Row style={{ textAlign: "left" }}>
    <Col lg={{ span: 4, offset: 4 }} style={{ textAlign: "center" }}>
      Selecionou o IHTP SUCCESS BOX -{" "}
      <b>{this.props.DescriptionProdutoSelecionado}</b>. <br />
      Clique em adicionar ao carrinho para finalizar o seu pedido.
    </Col>
  </Row>
  <br />
  <Row style={{ textAlign: "center" }}>
    <Col lg={12}>
      {this.state.loading ? (
        <Button
          variant={"outlined"}
          disabled
          style={{
            background: "#ca006a",
            color: "white",
            width: isMobile ? "70%" : "20%"
          }}
        >
          <Spinner size="sm" color="white" />
        </Button>
      ) : (
        <Button
          variant={"outlined"}
          className="fake-link"
          style={{
            background: "#ca006a",
            color: "white",
            width: isMobile ? "70%" : "20%"
          }}
          onClick={() => {
            this.chechoutCompra();
          }}
        >
          Adiconar ao Carrinho
        </Button>
      )}
    </Col>
  </Row>
</Container>*/
}
