import React, { Component, Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import Button from "@material-ui/core/Button";
import { Row, Col, Container } from "react-bootstrap";
import { login, getPackPrice } from "../../actions/userActions";

import CircularProgress from "@material-ui/core/CircularProgress";

export default class Login extends React.Component {
  loginAccount = async e => {
    this.setState({ loading: true });
  };

  render() {
    return (
      /* Módulo de formulário */
      <Formik
        /* Definir dados iniciais a enviar para API */
        initialValues={{ email: "", password: "" }}
        /* Validar dados */
        validate={values => {
          let errors = {};
          if (!values.email) {
            errors.email = "Email necessário";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Endereço de email inválido";
          }
          return errors;
        }}
        /* Enviar dados para processamento */
        onSubmit={(values, { setSubmitting }) => {
          /* Enviar email e password */
          login(values.email, values.password).then(data => {
            if (data.message === "Login efetuado com sucesso") {
              /* Se possuir mensagem o login foi efetuado com sucesso */
              this.props.getLoginCallMessage(values.email, data.message);
              this.setState({ loading: false });
              setSubmitting(false);
              this.props.getMoradasFaturacaoCall();
              this.props.getPrices();
            } else {
              alert(data.message);
              /* Se não possuir login apresenta uma mensagem de erro */
              this.props.getLoginCallMessage(data.message);
              this.setState({ loading: false });
              setSubmitting(false);
            }
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
          /* and other goodies */
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{ display: this.props.show ? "block" : "none" }}
          >
            <Row style={{}}>
              <Col lg={12} style={{ textAlign: "left" }}></Col>
            </Row>
            <h3>Entre e subscreva</h3>
            <br />
            <h5 style={{ textAlign: "justify" }}>
              Para efetuar uma subscrição, necessita de entrar na sua conta.
              Caso não tenha nenhuma conta deve registar-se no{" "}
              <a
                style={{
                  cursor: "pointer",
                  color: "rgb(202, 0, 106)",
                  lineHeight: "5vh"
                }}
                onClick={this.props.showRegister}
              >
                "Criar Conta"
              </a>
              , caso já tenha conta e não sabe a palavra-passe deve recuperar no{" "}
              <a
                style={{
                  cursor: "pointer",
                  color: "rgb(202, 0, 106)",
                  lineHeight: "5vh"
                }}
                href={"https://www.ihavethepower.net/Account/Recuperar"}
                target="blank"
              >
                "Recuperar palavra-passe"
              </a>
            </h5>
            <TextField
              required
              id="outlined-required"
              label="Email"
              defaultValue=""
              margin="normal"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              fullWidth
              type="email"
              name="email"
              error={errors.email}
            />
            <TextField
              required
              id="outlined-required"
              label="Password"
              defaultValue=""
              margin="normal"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              fullWidth
              type="password"
              name="password"
            />
            <br />
            <br />
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isSubmitting}
              style={{ width: "100%" }}
            >
              {isSubmitting ? <CircularProgress /> : "Entrar na conta"}
            </Button>
            <Row style={{ marginTop: "3vh" }}>
              <Col lg={12} style={{ textAlign: "right" }}>
                <a
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "rgb(202, 0, 106)"
                  }}
                  href={"https://www.ihavethepower.net/Account/Recuperar"}
                  target="blank"
                >
                  Recuperar palavra-passe
                </a>
              </Col>
            </Row>
            <br />
            <Row style={{}}>
              <Col lg={12} style={{ textAlign: "right" }}>
                <a
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "rgb(202, 0, 106)"
                  }}
                  onClick={this.props.showRegister}
                >
                  Criar conta
                </a>
              </Col>
            </Row>
            {errors.email && touched.email && errors.email}
            {errors.password && touched.password && errors.password}
          </form>
        )}
      </Formik>
    );
  }
}
