import React, { Component, Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import Button from "@material-ui/core/Button";
import { Row, Col, Container } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";

import CircularProgress from "@material-ui/core/CircularProgress";

import { registo, login } from "../../actions/userActions";

export default class Register extends React.Component {
  render() {
    return (
      <Formik
        /* Dados a enviar para a API */
        initialValues={{ nomeCompleto: "", email: "", termos: false }}
        validate={values => {
          let errors = {};
          if (!values.email) {
            errors.email = "Email necessário";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Endereço de email inválido";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (values.password != values.password2) {
            alert("As passwords inseridas devem coincidir");
            setSubmitting(false);
          } else if (!values.termos) {
            alert("Deve aceitar os termos e condições para prosseguir");
            setSubmitting(false);
          } else {
            registo(values.email, values.password, values.nome).then(data => {
              /* Enviar email e password */
              if (data.message === "Login efetuado com sucesso") {
                login(values.email, values.password).then(data => {
                  if (data.message) {
                    /* Se possuir mensagem o login foi efetuado com sucesso */
                    this.props.getLoginCallMessage(values.email, data.message);
                    this.setState({ loading: false });
                    setSubmitting(false);
                    this.props.getMoradasFaturacaoCall();
                    this.props.getPrices();
                  } else {
                    /* Se não possuir login apresenta uma mensagem de erro */
                    this.props.getLoginCallMessage(data.message);
                    this.setState({ loading: false });
                    setSubmitting(false);
                  }
                });
              } else {
                alert(data.message);
              }

              setSubmitting(false);
            });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
          /* and other goodies */
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{ display: this.props.show ? "block" : "none" }}
          >
            <Row style={{}}>
              <Col lg={12} style={{ textAlign: "left" }}>
                <a
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={this.props.showLogin}
                >
                  {"<"} Voltar
                </a>
              </Col>
            </Row>
            <h3>Crie a sua conta</h3>
            <Row>
              <Col lg={12}>
                <TextField
                  required
                  id="outlined-required"
                  label="Nome Completo"
                  defaultValue=""
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nome}
                  fullWidth
                  type="text"
                  name="nome"
                  error={errors.nome}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <TextField
                  required
                  id="outlined-required"
                  label="Email"
                  defaultValue=""
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  fullWidth
                  type="email"
                  name="email"
                  error={errors.email}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <TextField
                  required
                  id="outlined-required"
                  label="Insira uma password"
                  defaultValue=""
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  fullWidth
                  type="password"
                  name="password"
                  error={errors.password}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <TextField
                  required
                  id="outlined-required"
                  label="Repita a password"
                  defaultValue=""
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password2}
                  fullWidth
                  type="password"
                  name="password2"
                  error={errors.password2}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "3vh" }}>
              <Col lg={12}>
                <Checkbox
                  checked={values.termos}
                  value={values.termos}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="termos"
                  color="primary"
                />{" "}
                <span>
                  Aceito a politica de dados. Para obter mais detalhes, consulte
                  a nossa{" "}
                  <a
                    target="blank"
                    href={
                      "https://www.ihavethepower.net/Conteudo/Ver/TermosPrivacidade"
                    }
                  >
                    politica de dados
                  </a>
                  .
                </span>
              </Col>
            </Row>
            <Row style={{ marginTop: "3vh" }}>
              <Col lg={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ width: "100%" }}
                >
                  {isSubmitting ? <CircularProgress /> : "Registar"}
                </Button>
              </Col>
            </Row>
            <br />
            <Row style={{ marginTop: "3vh" }}>
              <Col lg={12} style={{ textAlign: "right" }}>
                <a
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "rgb(202, 0, 106)"
                  }}
                  href={"https://www.ihavethepower.net/Account/Recuperar"}
                  target="blank"
                >
                  Recuperar palavra-passe
                </a>
              </Col>
            </Row>
            <br />
            <Row style={{}}>
              <Col lg={12} style={{ textAlign: "right" }}>
                <a
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "rgb(202, 0, 106)"
                  }}
                  onClick={this.props.showLogin}
                >
                  Já tenho conta
                </a>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    );
  }
}
