// 23-07-2019 - AFONSO - Funcao para obter detalhe das comissoes
export function comprarIHTPSUCCESS(ID_Pack) {
  return new Promise((resolve, reject) => {
    return fetch("/home/comprarIHTPSUCCESS", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Pack: ID_Pack
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
