import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";

export default class HeroBarraHorizontal extends React.Component {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          minWidth: "100%",
          height: "30vh",
          top: isMobile ? "25vh" : "35vh",
          backgroundColor: this.props.background
        }}
      ></div>
    );
  }
}
